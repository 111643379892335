
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import PopoverInput from "@/components/popover-input.vue";
import LsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import LsUpload from "@/components/ls-upload.vue";
import FileItem from "./file-item.vue";
import {
  apiFileAddCate,
  apiFileEditCate,
  apiFileDelCate,
  apiFileList,
  apiFileListCate,
  apiFileDel,
  apiFileMove,
  apiFileRename,
} from "@/api/app";
import { RequestPaging } from "@/utils/util";
@Component({
  components: {
    LsPagination,
    PopoverInput,
    LsDialog,
    LsUpload,
    FileItem,
  },
})
export default class Material extends Vue {
  @Prop({ default: "image" }) type!: "image" | "video" | "file";
  @Prop({ default: "100" }) size!: string;
  @Prop({ default: "popup" }) mode!: "pages" | "popup";
  @Prop({ default: 20 }) pageSize!: number;
  @Prop() limit!: number;
  // data
  name = "";
  moveId = 0;
  checkAll = false;
  isIndeterminate = false;
  currentId = "";
  fileList = [];
  groupeLists: any[] = [];
  pager = new RequestPaging({ size: this.pageSize });
  selectList: any[] = [];

  get typeValue() {
    switch (this.type) {
      case "image":
        return 10;
      case "video":
        return 20;
      case "file":
        return 30;
    }
  }

  @Watch("selectList")
  selectListChange(val: any[]) {
    this.$emit("change", val);
    if (val.length == this.pager.lists.length && val.length !== 0) {
      this.isIndeterminate = false;
      this.checkAll = true;
      return;
    }
    if (val.length > 0) {
      this.isIndeterminate = true;
    } else {
      this.checkAll = false;
      this.isIndeterminate = false;
    }
  }

  get selectStatus() {
    return (id: any) => this.selectList.find((item) => item.id == id);
  }
  async getGroupeList() {
    const res = await apiFileListCate({
      type: this.typeValue,
      page_type: 1,
    });
    const item = [
      {
        name: "全部",
        id: "",
      },
      {
        name: "未分组",
        id: 0,
      },
    ];
    this.groupeLists = res?.lists;
    this.groupeLists.unshift(...item);
  }

  getList(): void {
    if (this.mode == "pages") {
      this.clearSelectList();
    }
    this.pager.request({
      callback: apiFileList,
      params: {
        type: this.typeValue,
        cid: this.currentId,
        name: this.name,
      },
    });
  }
  addGroupe(value: string, id: number) {
    if (!value) return this.$message.error("请输入分组名称");
    apiFileAddCate({
      type: this.typeValue,
      pid: id | 0,
      name: value,
    }).then((res) => {
      this.getData();
    });
  }
  editGroupe(value: string, id: number) {
    apiFileEditCate({
      name: value,
      id,
    }).then((res) => {
      this.getData();
    });
  }
  delGroupe(id: number) {
    apiFileDelCate({
      id,
    }).then((res) => {
      this.getData();
    });
  }

  async getData() {
    this.pager.loading = true;
    await this.getGroupeList();
    this.pager.loading = false;
    this.getList();
  }

  currentChange({ id }: any) {
    this.name = "";
    this.currentId = id;
    this.getList();
  }

  selectFile(item: any) {
    let index = this.selectList.findIndex((items: any) => items.id == item.id);
    if (index != -1) {
      this.selectList.splice(index, 1);
      return;
    }
    if (this.mode == "popup" && this.selectList.length == this.limit) {
      if (this.limit == 1) {
        this.selectList = [];
        this.selectList.push(item);
        return;
      }
      this.$message.warning("已达到选择上限");
      return;
    }
    this.selectList.push(item);
  }

  selectAll(value: boolean) {
    this.isIndeterminate = false;
    if (value) {
      this.selectList = [...this.pager.lists];
      return;
    }
    this.clearSelectList();
  }

  batchFileDel(id: any[]) {
    let ids = id ? id : this.selectList.map((item) => item.id);
    apiFileDel({
      ids,
    }).then((res) => {
      this.getList();
    });
  }

  batchFileMove() {
    let ids = this.selectList.map((item) => item.id);
    apiFileMove({
      ids,
      cid: this.moveId,
    }).then((res) => {
      this.moveId = 0;
      this.getList();
    });
  }

  fileRename(value: string, id: number) {
    apiFileRename({
      id,
      name: value,
    }).then(() => {
      this.getList();
    });
  }

  delImage(id: number) {
    this.selectList = this.selectList.filter((item) => item.id != id);
  }
  clearSelectList() {
    this.selectList = [];
  }
  created() {
    this.getData();
  }
}
