
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FileItem extends Vue {
  @Prop({ default: () => {} }) item!: any;
  @Prop({ default: "100" }) size!: string;
  @Prop({ default: "image" }) type!: string;

  fileIcon = require("@/assets/images/file.png");

  getUrl(value: any) {
    if (value.ext) {
      if (["jpg", "png", "jpeg", "gift", ""].indexOf(value.ext) > -1) {
        return value.uri;
      } else {
        return this.fileIcon;
      }
    } else {
      return value.uri;
    }
  }
}
